import "./src/assets/css/styles.css";

// console.log(
//   `%c
//       .-.
//      (o.o)
//       |=| -- welkom op de website van MiraVVE
//      __|__
//    //.=|=.\\\\
//   // .=|=. \\\\
//   \\\\ .=|=. //
//    \\\\(_=_)//
//     (:| |:)
//      || ||
//      () ()
//      || ||
//      || ||
//     ==' '==`,
//   "font-family:monospace;"
// );

console.log(
  `%c
                             ^
                _______     ^^^
               |xxxxxxx|  _^^^^^_
               |xxxxxxx| | [][]  |
            ______xxxxx| |[][][] |
           |++++++|xxxx| | [][][]|      MIRA VVE
           |++++++|xxxx| |[][][] |
           |++++++|_________ [][]|
           |++++++|=|=|=|=|=| [] |
           |++++++|=|=|=|=|=|[][]|
___________|++HH++|  _HHHH__|   _________   _________  _________
         _______________   ______________      ______________
__________________  ___________    __________________    ____________
  `,
  "font-family:monospace;"
);
